import React, { useState, useEffect, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { toast } from "react-toastify";
import API from "../../helpers/api";
import FNSpinner from "../../components/FNSpinner";

const AddReservation = () => {

    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [totalamt, setTotalAmt] = useState(0);
    const [agency, setAgency] = useState();
    const [checkin, setDateIn] = useState();
    const [checkout, setDateOut] = useState();
    const [days, setDays] = useState();
    const [currency, setCurrency] = useState();
    const [deposit, setDeposit] = useState(0);
    const [balance, setBalance] = useState(totalamt);
    const [children, setChildren] = useState();
    const [age, setAge] = useState();
    const [status, setStatus] = useState();

    const history = useHistory();

    const createNewRow = () => ({
        id: Date.now(),
        roomType: '',
        mealplan: '',
        roomRate: '',
        roomNo: '',
        roomPeople: '',
        roomNight: '',
        roomTotal: '',
    });

    const addRow = () => {
        setRows([...rows, createNewRow()]);
    };

    const deleteRow = (id) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const roomRates = {
        "Single Room": {
            "Bed & Breakfast": 180,
            "Half Board": 200,
            "Full Board": 220
        },
        "Double / Twin Room": {
            "Bed & Breakfast": 110,
            "Half Board": 130,
            "Full Board": 150
        },
        "Trip Room": {
            "Bed & Breakfast": 95,
            "Half Board": 110,
            "Full Board": 130
        },
    };

    const handleInputChange = (id, field, value) => {
        setRows((prevRows) => prevRows.map((row) => {
            if (row.id === id) {
                const updatedRow = { ...row, [field]: value };

                // Determine the room rate based on room type and meal plan
                if (field === 'roomType' || field === 'mealplan') {
                    const roomType = field === 'roomType' ? value : updatedRow.roomType;
                    const mealplan = field === 'mealplan' ? value : updatedRow.mealplan;
                    if (roomType in roomRates) {
                        if (typeof roomRates[roomType] === 'object') {
                            updatedRow.roomRate = roomRates[roomType][mealplan] || 0;
                        } else {
                            updatedRow.roomRate = roomRates[roomType] || 0;
                        }
                    } else {
                        updatedRow.roomRate = 0;
                    }
                }

                // Parse float values for the relevant fields
                const roomRate = parseFloat(updatedRow.roomRate) || 0;
                const roomNo = parseFloat(field === 'roomNo' ? value : updatedRow.roomNo) || 0;
                const roomNight = parseFloat(field === 'roomNight' ? value : updatedRow.roomNight) || 0;

                // Update roomTotal if one of the relevant fields is changed
                if (field === 'roomType' || field === 'mealplan' || field === 'roomRate' || field === 'roomNo' || field === 'roomNight') {
                    updatedRow.roomTotal = roomRate * roomNo * roomNight;
                }

                return updatedRow;
            }
            return row;
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            agency,
            checkin,
            checkout,
            days,
            currency,
            deposit,
            balance,
            children,
            age,
            totalamt,
            status,
            rows: rows.map(row => ({
                roomType: row.roomType,
                mealplan: row.mealplan,
                roomRate: row.roomRate,
                roomNo: row.roomNo,
                roomPeople: row.roomPeople,
                roomNight: row.roomNight,
                roomTotal: row.roomTotal,
            }))
        };

        try {
            const response = await API.post("/reservation", data);
            console.log(response)
            setLoading(false);
            toast.success(`Reservation Added Successfully`);
            history.push(`/reservations`);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Error Encountered while Adding Reservation");
        }
    };

    useEffect(() => {
        setRows([createNewRow()]);
    }, []);

    useEffect(() => {
        const total = rows.reduce((acc, row) => acc + (parseFloat(row.roomTotal) || 0), 0);
        setTotalAmt(total);
    }, [rows]);

    useEffect(() => {
        if (checkin && checkout) {
            const checkinDate = new Date(checkin);
            const checkoutDate = new Date(checkout);
            const timeDifference = checkoutDate - checkinDate;
            const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
            setDays(dayDifference);
        } else {
            setDays('');
        }
    }, [checkin, checkout]);

    useEffect(() => {
        const calculatedBalance = totalamt - deposit;
        setBalance(calculatedBalance);
    }, [totalamt, deposit]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="d-sm-flex align-items-center justify-content-between mb-3">
                        <h4 className="mb-sm-0 font-size-18">Reservation Booking Details</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li class="breadcrumb-item"><Link to="/reservations">Back To Reservations</Link></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="mt-2 fw-semibold">Agency Details</h4>
                            {/* <p class="text-muted mt-3">Enter In Customer Booking Details for All that is required from this</p> */}
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="formrow-email-input" class="form-label">Tour and Travel Agency</label>
                                        <input type="text" class="form-control" placeholder="Enter Tour and Travel Agency"
                                            value={agency}
                                            onChange={(e) => setAgency(e.target.value)} />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="formrow-password-input" class="form-label">Check In Date</label>
                                        <input type="date" class="form-control"
                                            value={checkin}
                                            onChange={(e) => setDateIn(e.target.value)} />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="formrow-password-input" class="form-label">Check Out Date</label>
                                        <input type="date" class="form-control"
                                            value={checkout}
                                            onChange={(e) => setDateOut(e.target.value)} />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="formrow-password-input" class="form-label">Number of Days</label>
                                        <input type="text" class="form-control"
                                            value={days}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label for="formrow-password-input" class="form-label">Status</label>
                                        <select class="form-select"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)
                                            }>
                                            <option selected="">Select Status</option>
                                            <option value="Confirmed">Confirmed</option>
                                            <option value="Tentative">Tentative</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label for="formrow-password-input" class="form-label">Currency</label>
                                        <select class="form-select"
                                            value={currency}
                                            onChange={(e) => setCurrency(e.target.value)
                                            }>
                                            <option selected="">Select Currency</option>
                                            <option value="USD">USD</option>
                                            <option value="UGX">UGX</option>
                                            <option value="EUROS">EUROS</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label class="form-label">Payment Deposit</label>
                                        <CurrencyFormat
                                            value={deposit}
                                            thousandSeparator={true}
                                            className="form-control"
                                            placeholder="Enter Deposit Amount"
                                            onValueChange={(values) => {
                                                const { value } = values;
                                                setDeposit(parseFloat(value) || 0);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label class="form-label">Balance</label>
                                        <CurrencyFormat
                                            value={balance}
                                            thousandSeparator={true}
                                            className="form-control"
                                            placeholder="Balance"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label class="form-label">Number of Children</label>
                                        <input type="text" class="form-control" placeholder="Enter No of Children"
                                            value={children}
                                            onChange={(e) => setChildren(e.target.value)} />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label for="formrow-password-input" class="form-label">Age Range</label>
                                        <select class="form-select"
                                            value={age}
                                            onChange={(e) => setAge(e.target.value)
                                            }>
                                            <option selected="">Select Age Range</option>
                                            <option>Between 6 and 12</option>
                                            <option>Below 6</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 class="mt-2 fw-semibold">Room Booking Details</h4>
                            <div className='d-flex'>
                                <h4 className="card-title">
                                    <button className="btn btn-sm btn-soft-primary mx-3" onClick={addRow}>
                                        <i className="mdi mdi-eye-outline"></i>Add Room Details
                                    </button>
                                </h4>
                            </div>
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Room Type</th>
                                                <th>Meal Plan</th>
                                                <th>Rate Per Room</th>
                                                <th>No of Rooms</th>
                                                <th>No of People</th>
                                                <th>No of Nights</th>
                                                <th>{`Total (${currency})`}</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map((row) => (
                                                <tr key={row.id}>
                                                    <td>
                                                        <select className="form-select" aria-label="Select example"
                                                            value={row.roomType}
                                                            onChange={(e) => handleInputChange(row.id, 'roomType', e.target.value)}>
                                                            <option>Select Room</option>
                                                            <option>Single Room</option>
                                                            <option>Double / Twin Room</option>
                                                            <option>Trip Room</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className="form-select" aria-label="Select example"
                                                            value={row.mealplan}
                                                            onChange={(e) => handleInputChange(row.id, 'mealplan', e.target.value)}>
                                                            <option>Select Meal </option>
                                                            <option>Bed & Breakfast</option>
                                                            <option>Full Board</option>
                                                            <option>Half Board</option>
                                                        </select>
                                                    </td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={row.roomRate}
                                                            thousandSeparator={true}
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={row.roomNo}
                                                            onChange={(e) => handleInputChange(row.id, 'roomNo', e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={row.roomPeople}
                                                            onChange={(e) => handleInputChange(row.id, 'roomPeople', e.target.value)} />
                                                    </td>
                                                    <td className="text-end">
                                                        <input type="text" className="form-control"
                                                            value={row.roomNight}
                                                            onChange={(e) => handleInputChange(row.id, 'roomNight', e.target.value)} />
                                                    </td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={row.roomTotal}
                                                            thousandSeparator={true}
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <ul className="list-unstyled hstack gap-1 mb-0">
                                                            <li onClick={() => deleteRow(row.id)} data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Delete" data-bs-original-title="Delete">
                                                                <a href="#jobDelete" className="btn btn-sm btn-soft-danger"><i className="mdi mdi-delete-outline"></i></a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="6" className="border-0 text-end"><strong>Total Amount</strong></td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={totalamt}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        className="form-control"
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div class="mt-2">
                                <button type="button" class="btn btn-primary" onClick={handleSubmit}>
                                    {loading ? <FNSpinner /> : "Add Reservations Details"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default AddReservation