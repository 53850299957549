import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import API from "../../helpers/api";
import FNSpinner from "../../components/FNSpinner";

const ReservationDetails = ({ match }) => {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reservation, setReservations] = useState(false);

    const { id } = match.params;

    const reservationDetails = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/reservation/${id}`);
            setReservations(res?.data.reservation);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadRooms = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/reservation/rooms/${id}`);
            setRooms(res?.data.rooms);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadRooms();
        reservationDetails();
    }, []);


    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="d-sm-flex align-items-center justify-content-between mb-3">
                        <h4 className="mb-sm-0 font-size-18">Reservation Number: {reservation.id} Booking Details</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/reservations">Back To Reservations</Link></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <div className="table-responsive">
                                    <table className="table table-striped-columns mb-0">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Tour and Travel Agency</th>
                                                <td>{reservation.agency}</td>
                                                <th scope="row">Status</th>
                                                <td>{reservation.status}</td>
                                                <th scope="row">Currency</th>
                                                <td>{reservation.currency}</td>
                                                <th scope="row">Booking Date</th>
                                                <td>{moment(reservation.createAt).format('YYYY-MM-DD')}</td>
                                                <th scope="row">Total Amount</th>
                                                <td><CurrencyFormat value={reservation.totalamt} displayType="text" thousandSeparator /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Check In Date</th>
                                                <td>{moment(reservation.datein).format('YYYY-MM-DD')}</td>
                                                <th scope="row">Check Out Date</th>
                                                <td>{moment(reservation.dateout).format('YYYY-MM-DD')}</td>
                                                <th scope="row">Days</th>
                                                <td>{reservation.days}</td>
                                                <th scope="row">Payment Deposit</th>
                                                <td><CurrencyFormat value={reservation.deposit} displayType="text" thousandSeparator /></td>
                                                <th scope="row">Balance</th>
                                                <td><CurrencyFormat value={reservation.balance} displayType="text" thousandSeparator /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className='d-flex'>
                                <h4 className="card-title">
                                    Room Details
                                </h4>
                            </div>
                            <div className="col-lg-12">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered border-secondary table-sm">
                                        <thead className="table-dark">
                                            <tr>
                                                <th>Room Type</th>
                                                <th>Meal Plan</th>
                                                <th>Rate Per room</th>
                                                <th>No of Rooms</th>
                                                <th>No of People</th>
                                                <th>No of Nights</th>
                                                <th class="text-end">{`Amount (${reservation.currency})`}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rooms &&
                                                rooms.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.roomType}</td>
                                                        <td>{item.mealplan}</td>
                                                        <td>{item.roomRate}</td>
                                                        <td>{item.roomNo}</td>
                                                        <td>{item.roomPeople}</td>
                                                        <td>{item.roomNight}</td>
                                                        <td class="text-end">
                                                            <CurrencyFormat
                                                                value={item.roomTotal}
                                                                displayType="text"
                                                                thousandSeparator
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td colspan="6" class="text-end">
                                                    <strong>{`Total Amount (${reservation.currency})`}</strong></td>
                                                <td class="text-end"><h5 class="m-0">
                                                    <CurrencyFormat
                                                        value={reservation.totalamt}
                                                        displayType="text"
                                                        thousandSeparator
                                                    />
                                                </h5></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ReservationDetails;