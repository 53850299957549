import React, { Fragment, useState, useEffect, useRef } from "react";
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactToPrint from "react-to-print";
import CurrencyFormat from 'react-currency-format';
import API from "../../helpers/api";
import logo from './logo.jpg'

const InvoiceDetails = ({ match }) => {

    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reservation, setReservations] = useState();

    const { id } = match.params;

    const componentRef = useRef();

    const reservationDetails = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/reservation/${id}`);
            setReservations(res?.data.reservation);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadRooms = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/reservation/rooms/${id}`);
            setRooms(res?.data.rooms);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadRooms();
        reservationDetails();
    }, []);
    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="d-sm-flex align-items-center justify-content-between mb-3">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><Link to="/reservations">Back To Reservations</Link></li>
                        </ol>
                        <div className="page-title-right">
                            <ReactToPrint
                                trigger={() => <button class="btn btn-warning w-md waves-effect waves-light">Print Invoice</button>}
                                content={() => componentRef.current}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card" ref={componentRef}>
                <div className="card-body">
                    <div className="row d-flex align-items-center">
                        <div className="col-6">
                            <div style={{ width: '100%', height: '20vh', overflow: 'hidden' }}>
                                <img src={logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className="col-6 justify-content-end">
                            <h5>
                                <span>Invoice Date: {reservation ? moment(reservation.createAt).format('YYYY-MM-DD') : ""}</span>
                                <span className="mx-3">Invoice No: {reservation ? reservation.id : ""} / 2024</span>
                            </h5>
                            <h5>
                                <span>Check In: {reservation ? moment(reservation.checkin).format('YYYY-MM-DD') : ""}</span>
                                <span className="mx-3">Check Out: {reservation ? moment(reservation.checkout).format('YYYY-MM-DD'): ""}</span>
                            </h5>
                        </div>
                    </div>
                    <div className="row d-flex" >
                        <div className="col-12" >
                            <section className='d-flex flex-row justify-content-between'>
                                <div>
                                    <h4> M/S : {reservation ? reservation.agency : ''}</h4>
                                    {/* <h5>  Address: {supply.customer ? supply.customer.address : ''}</h5> */}
                                </div>

                            </section>
                            <section className="mt-4">
                                {/* <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Job Card Items Worked On</h3>
                                </div> */}
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered border-secondary table-sm">
                                        <thead className="table-dark">
                                            <tr>
                                                <th>Room Type</th>
                                                <th>Meal Plan</th>
                                                <th>Rate Per room</th>
                                                <th>No of Rooms</th>
                                                <th>No of People</th>
                                                <th>No of Nights</th>
                                                <th class="text-end">{`Amount (${reservation ? reservation.currency : ""})`}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rooms &&
                                                rooms.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.roomType}</td>
                                                        <td>{item.mealplan}</td>
                                                        <td>{item.roomRate}</td>
                                                        <td>{item.roomNo}</td>
                                                        <td>{item.roomPeople}</td>
                                                        <td>{item.roomNight}</td>
                                                        <td class="text-end">
                                                            <CurrencyFormat
                                                                value={item.roomTotal}
                                                                displayType="text"
                                                                thousandSeparator
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td colspan="6" class="text-end">
                                                    <strong>{`Total Amount (${reservation ? reservation.currency : ""})`}</strong></td>
                                                <td class="text-end"><h5 class="m-0">
                                                    <CurrencyFormat
                                                        value={reservation ? reservation.totalamt : ""}
                                                        displayType="text"
                                                        thousandSeparator
                                                    />
                                                </h5></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>
                            <footer>
                                <ul className='list-unstyled'>
                                    <li>Payment terms: A non-refundable 20% deposit of the booking value is required to confirm the booking</li>
                                    <li>Bank transfer to Aadvark Tours and Safari Lodge Ltd</li>
                                    <li>Bank of Africa Uganda Ltd. - Kampala - P.O. Box 2971 SWIFT CODE: AFRIUGKA XXX</li>
                                    <li>Account: 04262070016 (USD) OR 04262070003 (UGX)</li>
                                </ul>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default InvoiceDetails