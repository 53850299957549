import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';
import FNTable from './FNTable';
import AddPayments from './AddPayments'
import API from "../../helpers/api";
import FNModal from '../../components/FNModal'
import FSpinner from '../../components/FNSpinner/FSpinner';

const Payments = () => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState("");
    const [reservations, setReservations] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const limit = 10;

    const handleClose = () => setShowModal(false);

    const handleShow = (id) => {
        setId(id)
        setShowModal(true)
    };

    const handleDelete = async (id) => {
        setId(id)
        try {
            await API.delete(`/customers/${id}`);
        } catch (error) {
            console.error('There was an error deleting the item:', error);
        }
    }

    const handleView = (id) => {
        // history.push(`/fleet/vehicle/${id}`);
    };

    const loadReservations = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/reservation?page=${currentPage}&limit=${limit}`);
            setReservations(res?.data.reservation);
            setTotalPages(res?.data.totalPages);
            setTotalRecords(res?.data.totalRecords);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(() => {
        loadReservations();
    }, [currentPage]);

    const tableColumns = [
        { key: 'fullname', label: 'Full Names' },
        { key: 'address', label: 'Address' },
        { key: 'custtype', label: 'Customer Type' },
    ];

    return (
        <Fragment>
            <FNModal
                showModal={showModal}
                handleClose={handleClose}
                lg="lg"
                title={`Add Payment For Booking Reference: ${id}`}
            >
                <AddPayments close={handleClose} refresh={loadReservations} id={id} />
            </FNModal>
            <div class="row">
                <div class="col-12">
                    <div class="page-title-b d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Payment Details</h4>
                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item"><Link to="/ict/assets">Payments</Link></li>
                                <li class="breadcrumb-item active">Listing</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row mb-2">
                                            <div class="col-sm-4">
                                                <div class="search-box me-2 mb-2 d-inline-block">
                                                    <div class="position-relative">
                                                        <input type="text" class="form-control" id="searchTableList" placeholder="Search..." />
                                                        <i class="bx bx-search-alt search-icon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="col-sm-8">
                                                <div class="text-sm-end">
                                                    <button type="submit" class="btn btn-primary waves-effect waves-light" onClick={handleShow}>Add New Customer</button>
                                                </div>
                                            </div> */}
                                        </div>
                                        {loading ? <FSpinner /> :
                                            <FNTable
                                                columns={tableColumns}
                                                data={reservations}
                                                handleShow={handleShow}
                                                onViewDetails={handleView}
                                                handleDelete={handleDelete}
                                            />
                                        }
                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info" role="status" aria-live="polite">
                                                    Showing {(currentPage - 1) * limit + 1} to {Math.min(currentPage * limit, totalRecords)} of {totalRecords} Records
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers">
                                                    <ul className="pagination">
                                                        <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <a onClick={handlePrevious} className="page-link">Previous</a>
                                                        </li>
                                                        {[...Array(totalPages)].map((_, index) => (
                                                            <li key={index} className={`paginate_button page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                                <a onClick={() => setCurrentPage(index + 1)} className="page-link">{index + 1}</a>
                                                            </li>
                                                        ))}
                                                        <li className={`paginate_button page-item next ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                            <a onClick={handleNext} className="page-link">Next</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Payments