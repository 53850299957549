/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

const FNTable = ({ data, viewDetails, invoiceDetails, handleEdit, handleDelete }) => {

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    return (
        <div class="table-responsive">
            <table class="table align-middle table-striped table-sm">
                <thead className="table-dark">
                    <tr>
                        <th>Booking Date</th>
                        <th>Tour and Travel Agency</th>
                        <th>Status</th>
                        <th>Check In Date</th>
                        <th>Check Out Date</th>
                        <th>Days</th>
                        <th>Currency</th>
                        <th>Invoice Amount</th>
                        <th>Deposit</th>
                        <th>Balance</th>
                        <th>View Invoice</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.map((item) => (
                            <tr key={item.id}>
                                {/* <td>{truncateText(item.site, 20)}</td> */}
                                <td>{moment(item.createAt).format('YYYY-MM-DD')}</td>
                                <td>{item.agency}</td>
                                <td>{item.status}</td>
                                <td>{moment(item.datein).format('YYYY-MM-DD')}</td>
                                <td>{moment(item.dateout).format('YYYY-MM-DD')}</td>
                                <td>{item.days}</td>
                                <td>{item.currency}</td>
                                <td>
                                    <CurrencyFormat
                                        value={item.totalamt}
                                        displayType="text"
                                        thousandSeparator
                                    />
                                </td>
                                <td>
                                    <CurrencyFormat
                                        value={item.deposit}
                                        displayType="text"
                                        thousandSeparator
                                    />
                                </td>
                                <td>
                                    <CurrencyFormat
                                        value={item.balance}
                                        displayType="text"
                                        thousandSeparator
                                    />
                                </td>
                                {/* <td><div class="d-inline-block me-2" data-bs-toggle="tooltip" data-bs-placement="top">
                                    <a onClick={() => viewDetails(item.id)} class="btn btn-success btn-sm">Generate Invoice</a>
                                </div></td> */}

                                <td><div class="d-inline-block me-2" data-bs-toggle="tooltip" data-bs-placement="top">
                                    <a onClick={() => invoiceDetails(item.id)} class="btn btn-warning btn-sm">Print Invoice</a>
                                </div></td>
                                <td>
                                    <div class="d-inline-block me-2">
                                        <a onClick={() => viewDetails(item.id)} class="action-icon text-primary" style={{ cursor: 'pointer' }}>
                                            <i class="mdi mdi-eye font-size-20"></i></a>
                                    </div>
                                    <div class="d-inline-block me-2">
                                        <a onClick={() => handleEdit(item.id)} class="action-icon text-warning" style={{ cursor: 'pointer' }}>
                                            <i class="mdi mdi-comment-edit-outline font-size-20"></i></a>
                                    </div>
                                    <div class="d-inline-block me-2">
                                        <a onClick={() => handleDelete(item.id)} class="action-icon text-danger" style={{ cursor: 'pointer' }}>
                                            <i class="mdi mdi-trash-can font-size-20"></i></a>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    )
}

export default FNTable