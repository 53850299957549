import React, { useState, useEffect } from 'react';
import API from "../../helpers/api";
import FNSpinner from "../../components/FNSpinner";

const FNFilters = ({ onFilter, setData, floading }) => {
    const [projects, setProjects] = useState([]);
    const [sites, setSites] = useState([]);
    const [theme, setTheme] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState("");
    const [selectedThemeId, setSelectedThemeId] = useState("");
    const [loading, setLoading] = useState(false);

    const [themeId, setThemeId] = useState('');
    const [siteId, setSiteId] = useState('');
    const [inspectionDateFrom, setInspectionDateFrom] = useState('');
    const [inspectionDateTo, setInspectionDateTo] = useState('');

    const handleProjectChange = (e) => setSelectedProjectId(e.target.value);
    const handleThemeChange = (e) => setSelectedThemeId(e.target.value);

    const filteredSites = sites.filter(
        (site) => site.project.id === parseInt(selectedProjectId, 10)
    );

    const filteredTemplate = parameters.filter(
        (p) => p.theme.id === parseInt(selectedThemeId, 10)
    );

    const getProjects = async () => {
        try {
            const response = await API.get("/projects/all");
            setProjects(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const loadParameters = async () => {
        setLoading(true);
        try {
            const res = await API.get("/monitoring/template/config/all");
            setParameters(res.data);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const getSites = async () => {
        try {
            const response = await API.get("/sites/all");
            setSites(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getThemes = async () => {
        try {
            const response = await API.get("/theme/all");
            setTheme(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProjects();
        getSites();
        getThemes();
        loadParameters();
    }, []);

    const handleApplyFilters = () => {
        const filters = {
            themeId,
            siteId,
            inspectionDateFrom,
            inspectionDateTo
        };
        console.log('Filters to be applied:', filters);
        onFilter(filters);
    };

    const handleClearFilters = () => {
        setThemeId('');
        setSiteId('');
        setInspectionDateFrom('');
        setInspectionDateTo('');
        setSelectedProjectId('');
        setSelectedThemeId('');
        setParameters([]);
        setSites([]);
        setTheme([]);
        setData([]);
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card job-filter">
                    <div className="card-body p-3">
                        <div className="row">
                            {/* <div className="col-2">
                                <div className="position-relative">
                                    <select className="form-select"
                                        value={selectedProjectId}
                                        onChange={handleProjectChange}>
                                        <option>Select Project</option>
                                        {projects.map((pr) => (
                                            <option value={pr.id} key={pr.id}>
                                                {pr.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div> */}
                            {/* <div className="col-2">
                                <div className="position-relative">
                                    <select className="form-select"
                                        value={siteId}
                                        onChange={(e) => setSiteId(e.target.value)}>
                                        <option>Select facility</option>
                                        {filteredSites && filteredSites.length > 0 ? (
                                            filteredSites.map((st) => (
                                                <option value={st.id} key={st.id}>
                                                    {st.name}
                                                </option>
                                            ))
                                        ) : (
                                            <option>Project Has No Facilities</option>
                                        )}
                                    </select>
                                </div>
                            </div> */}
                            {/* <div className="col-2">
                                <div className="position-relative">
                                    <div id="datepicker1">
                                        <select className="form-select"
                                            value={selectedThemeId}
                                            onChange={handleThemeChange}>
                                            <option>Select Thematic Area</option>
                                            {theme.map((t) => (
                                                <option value={t.id} key={t.id}>
                                                    {t.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-2">
                                <div className="position-relative">
                                    <select
                                        className="form-select"
                                        aria-label="Select example"
                                        name="templateId"
                                        value={themeId}
                                        onChange={(e) => setThemeId(e.target.value)}
                                    >
                                        <option>Select Template</option>
                                        {filteredTemplate && filteredTemplate.length > 0 ? (
                                            filteredTemplate.map((th) => (
                                                <option value={th.id} key={th.id}>
                                                    {th.measurementName}
                                                </option>
                                            ))
                                        ) : (
                                            <option>Thematic Area Has No Templates</option>
                                        )}
                                    </select>
                                </div>
                            </div> */}
                            <div className="col-2">
                                <div className="position-relative">
                                    <input
                                        type="date"
                                        className="form-control form-control-solid"
                                        value={inspectionDateFrom}
                                        onChange={(e) => setInspectionDateFrom(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="position-relative">
                                    <input
                                        type="date"
                                        className="form-control form-control-solid"
                                        name="inspectionDateTo"
                                        value={inspectionDateTo}
                                        onChange={(e) => setInspectionDateTo(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="position-relative h-100 hstack gap-3">
                                    <div className="flex-shrink-0">
                                        <a href="#!" className="btn btn-warning">
                                            <i className="mdi mdi-filter-outline align-middle"></i>
                                            {floading ? (
                                                <FNSpinner />
                                            ) : (
                                                "Apply Filters"
                                            )}            </a>
                                        <a href="#!" className="btn btn-light mx-2"><i className="mdi mdi-refresh"></i> Clear Filters</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FNFilters;