import React, { Fragment } from 'react'
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Layout from './components/Layout';
import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard';
import Calendar from './pages/Calendar';
import Payments from './pages/Payments';
import Reservations from './pages/Reservations';
import InvoiceDetails from './pages/Reservations/InvoiceDetails';
import AddReservation from './pages/Reservations/AddReservation';
import EditReservation from './pages/Reservations/EditReservation';
import ReservationDetails from './pages/Reservations/ReservationDetails';

const App = () => {
  return (
    <Fragment>
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={Login} />
        <Layout>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/calendar" component={Calendar} />
          <Route exact path="/payments" component={Payments} />
          <Route exact path="/reservations" component={Reservations} />
          <Route exact path="/reservations/add" component={AddReservation} />
          <Route exact path="/reservations/edit/:id" component={EditReservation} />
          <Route exact path="/reservations/invoice/:id" component={InvoiceDetails} />
          <Route exact path="/reservations/details/:id" component={ReservationDetails} />
        </Layout>
      </Switch>
    </Fragment>
  )
}

export default App