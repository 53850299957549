import React, { useState, useEffect, useMemo, Fragment } from 'react';
import moment from 'moment';
import randomColor from 'randomcolor';
import API from '../../helpers/api';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const BookingCalendar = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reservations, setReservations] = useState([]);

    const loadReservations = async () => {
        setLoading(true);
        try {
            const res = await API.get('/reservation/all');
            setReservations(res.data.reservation);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching reservations:", error);
            setLoading(false);
        }
    };

    const agencyColors = useMemo(() => {
        const agencies = [...new Set(reservations.map(reservation => reservation.agency))];
        const colors = {};
        agencies.forEach(agency => {
            colors[agency] = randomColor({
                luminosity: 'dark', // Change to 'bright' if you want even brighter colors
                hue: 'random'
            });
        });
        return colors;
    }, [reservations]);

    useEffect(() => {
        loadReservations();
    }, []);

    useEffect(() => {
        const events = reservations.map((reservation) => ({
            start: new Date(reservation.checkin),
            end: new Date(reservation.checkout),
            title: reservation.agency,
            allDay: true,
            color: agencyColors[reservation.agency],
        }));
        setEvents(events);
    }, [reservations, agencyColors]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="pag-title-box d-sm-flex align-items-center justify-content-between mb-3">
                        <h4 className="mb-sm-0 font-size-18">Bookings Reservations Calendar</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <Calendar
                                    localizer={localizer}
                                    events={events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 500 }}
                                    eventPropGetter={(event) => ({
                                        style: {
                                            backgroundColor: event.color,
                                            // borderRadius: '0.5rem',
                                            opacity: 1,
                                            color: 'white',
                                            border: 2,
                                        },
                                    })}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default BookingCalendar;