import React from 'react'
import { Link } from 'react-router-dom'

const SideBar = () => {
    return (
        <div class="vertical-menu">
            <div data-simplebar class="h-100">
                <div id="sidebar-menu">
                    <ul class="metismenu list-unstyled" id="side-menu">
                        <li class="menu-title" key="t-menu">Home</li>
                        <li>
                            <Link to="/dashboard" class="waves-effect">
                                <i class="bx bx-chat"></i>
                                <span key="t-chat">Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/calendar" class="waves-effect">
                                <i class="bx bx-home-circle"></i>
                                <span key="t-dashboards">Booking Calendar</span>
                            </Link>
                        </li>
                        <li class="menu-title" key="t-pages">Reservations</li>
                        <li>
                            <Link to="/reservations/add" class="waves-effect">
                                <i class="bx bxs-user-detail"></i>
                                <span key="t-icons">Add Reservation</span>
                            </Link>
                            <Link to="/reservations" class="waves-effect">
                                <i class="bx bx-aperture"></i>
                                <span key="t-icons">All Reservations</span>
                            </Link>
                        </li>
                        <li class="menu-title" key="t-pages">Payments</li>
                        <li>
                            <Link to="/payments" class="waves-effect">
                                <i class="bx bx-map"></i>
                                <span key="t-maps">Payments</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/invoice" class="waves-effect">
                                <i class="bx bx-share-alt"></i>
                                <span key="t-multi-level">Invoices</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/report" class="waves-effect">
                                <i class="bx bx-file"></i>
                                <span key="t-file-manager">Reports</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SideBar